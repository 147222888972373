<template>
  <div class="container">
    <div class="search-bar">
      <a-form-model ref="searchRef"
                    :wrapperCol="{ span: 20 }"
                    :labelCol="{ span: 4 }">
        <a-row>
          <a-col :span="10">
            <a-form-model-item prop="searchTime" label="日期">
              <a-range-picker v-model="searchTime" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="7" :offset="1">
            <a-button class="ml-2" type="primary" html-type="submit" @click="initData">查询</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <base-table style="white-space: pre-line;"
                :columnsData="columns"
                :rowKey="rowKey"
                :tableData="tableData">
    </base-table>
  </div>

</template>

<script>
import { rowSpan } from "@/utils/index"
import { 
  financeAccountWDTSettleDetail
} from "@/api/finance/finance.js"

export default {
  components: {

  },
  props: {
    searchTime:  {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      tableData: [],
      columns: [
        {
          title: "订单ID",
          dataIndex: "order_no",
          align: "center",
          width: 130,
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "目标账户",
          dataIndex: "order_financial_account_name",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        // {
        //   title: "快递子订单",
        //   dataIndex: "suborder_no",
        //   align: "center",
        //   width: 140,
        //   customRender: (text, row) => {
        //     return {
        //       children: text,
        //       attrs: {
        //         rowSpan: row.order_noRowSpan, //需要合并的数据
        //       },
        //     }
        //   },
        // },
        {
          title: "快递单\n分成收入",
          dataIndex: "suborder_amount",
          align: "center",
          width: 90,
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "快递单\n承担退款",
          dataIndex: "suborder_refund_amount",
          align: "center",
          width: 90,
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "运单号",
          dataIndex: "waybill_no",
          align: "center",
          // width: 100,
        },
        {
          title: "发货仓库",
          dataIndex: "warehouse_no",
          width: 80,
          align: "center",
        },
        {
          title: "仓库财务账户",
          dataIndex: "financial_account_name",
          align: "center",
          width: 100,
        },
      ],
    }
  },
  created() {
    this.initData()
  },
  methods: {

    async initData() {
      if(!this.searchTime.length){
        return
      }

      let time_start = this.searchTime[0]
      let time_end   = this.searchTime[1]

      const { data, code } = await financeAccountWDTSettleDetail({
        time_start: time_start,
        time_end:   time_end,
      })
      if (code === 0) {
        let arr = []
        data.list.map((item) => {
          let obj = item

          item.wdt_order_list.map((el, i) => {
            obj = { ...obj, ...el }
            obj.id = obj.order_no + i
            arr.push(obj)
          })
        })

        this.tableData = rowSpan(arr, "order_no")
      }
    },
  },
}
</script>

<style lang="less">

</style>
